import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';  // Importer jwt-decode
import { GraphqlService } from './graphql.service';
import { User } from '../models/auth.models';


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  private authToken: string | null = null;
  public redirectUrl: string = '';  // Add this line

  private userProfileSubject = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject.asObservable();



  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();





    constructor(private http: HttpClient, private graphqlService :GraphqlService) {
      this.authToken = null;
    }

    decodeToken(token: string): any {
      return jwtDecode(token);
    }

    setAuthToken(token: string): void {
      this.authToken = token;
      localStorage.setItem('token', token);
   //   console.log('Token set in AuthService:', this.authToken);
    }

    getAuthToken(): string | null {
      this.authToken = localStorage.getItem('token');
  //    console.log('Token retrieved from AuthService:', this.authToken);
      return this.authToken;
    }

    isAuthenticated(): boolean {
      const token = this.getAuthToken();
      return !!token;
    }

    signIn(email: string, password: string): Observable<any> {
      const mutation = `
        mutation signinDASH($email: String!, $password: String!) {
          SigninDASH(email: $email, password: $password) {
            token
            id
            message
          }
        }
      `;
    
      const variables = {
        email,
        password,
      };
    
      return this.graphqlService.mutate(mutation, variables);
    }   

      

      logout(): void {
        this.isLoggedInSubject.next(false);
        this.userProfileSubject.next(null);  // Réinitialise le profil à null
        localStorage.removeItem('token');
      }



      getProfile(): Observable<any> {
        const query = `
        query GetProfile {
          getProfil {
            name
            email
            gender
            picture
            birthdate
            famille
            animaux
            lastVisiteDate
            ip
            region
          }
        }
        
        `;

        return this.graphqlService.query(query, { fetchPolicy: 'network-only' }).pipe(
          map((data: any) => {
            return data?.getProfil || [];
          })
        );
      }

      getUserById(id: number): Observable<any> {
        const query = `
          query GetUserById($id: Int) {
            userById(id: $id) {
                id
                name
                email
                roleId
            }
  
          }
        `;
        const variables = { id };
    
        
        return this.graphqlService.query(query, variables).pipe(
          map((data: any) => {
            return data?.userById || null;
          })
        );
      }

      getAllUsers(): Observable<any> {
        const query = `
        query GetallUsers {
          allUsers {
          id
            name
            email
            gender
            picture
            birthdate
            famille
            animaux
            createdAt
            lastVisiteDate
            ip
            region
            state
            roles{
               name
            }
          }
        }
        
        `;

        return this.graphqlService.query(query, { fetchPolicy: 'network-only' }).pipe(
          map((data: any) => {
            return data?.allUsers || [];
          })
        );
      }

      toggleUserState(id: number, state: string): Observable<any> {
        const mutation = `
        mutation ToggleUserState($id: Int!, $state: String!) {
          ToggleUserState(id: $id, state: $state) {
            id
            success
            message
          }
        }
      `;
      const variables = { id, state };
      return this.graphqlService.mutate(mutation, variables); // Utilisez la méthode mutate pour les mutations
  }

  updateUser(id: number, name: string, email: string, password:string, roleId:string): Observable<any> {
    const mutation = `
      mutation UpdateUser($id:Int!, $name: String!, $email: String!, $password : String!, $roleId : String!) {
        UpdateUser(id:$id, name: $name, email: $email, password : $password, roleId: $roleId) {
          success
          message
        }
      }
    `;
  
    const variables = {
      id,
      name,
      email,
      password,
      roleId
    };
  
    return this.graphqlService.mutate(mutation, variables); // Utilisez la méthode mutate pour les mutations
  }

  createUser(name: string, email: string, password:string, roleId:string): Observable<any> {
    const mutation = `
      mutation CreateUser($name: String!, $email: String!, $password : String!, $roleId : String!) {
        CreateUser(name: $name, email: $email, password : $password, roleId: $roleId) {
          success
          message
        }
      }
    `;
  
    const variables = {
      name,
      email,
      password,
      roleId
    };
  
    return this.graphqlService.mutate(mutation, variables); // Utilisez la méthode mutate pour les mutations
  }

      handleAuthError(error: any): Observable<never> {
        if (error && error.error && error.error.message === 'Token expired') {
          // Afficher le dialogue de token expiré
          console.log("token expired")
  //        this.dialog.open(TokenExpiredDialogComponent);
        }
        return throwError(error);
      }

}

