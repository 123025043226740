import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private timeoutId: any;
  private readonly TIMEOUT_DURATION = 20 * 60 * 1000; // 20 minutes

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    // Check if the session expired flag is set
    if (localStorage.getItem('sessionExpired') === 'true') {
      this.authService.logout();
      localStorage.removeItem('sessionExpired');
      this.authService.redirectUrl = '/account/login'; // Redirect to login after logout
      window.location.href = this.authService.redirectUrl;
      return;
    }

    this.resetTimer();

    // Handle page unload events
    window.addEventListener('beforeunload', (event) => {
      // Perform a basic check to avoid logout on reload
      const isReload = window.performance.navigation.type === 1;
      if (!isReload) {
        this.authService.logout();
      }
    });
  }

  @HostListener('document:mousemove')
  @HostListener('document:keydown')
  resetTimer() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      localStorage.setItem('sessionExpired', 'true');
      this.authService.logout();
      this.authService.redirectUrl = '/account/login'; // Redirect to login after logout
      window.location.href = this.authService.redirectUrl;
    }, this.TIMEOUT_DURATION);
  }
}
