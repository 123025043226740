// graphql.service.ts
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { gql } from 'apollo-angular';
import { WatchQueryFetchPolicy } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private apollo: Apollo) {}

  // Méthode générique pour effectuer une requête GraphQL
  query(query: string, variables?: any, options?: { headers?: HttpHeaders, fetchPolicy?: WatchQueryFetchPolicy }): Observable<any> {
    return this.apollo
      .watchQuery({
        query: gql(query),
        variables,
        context: {
          headers: options?.headers,
        },
        fetchPolicy: options?.fetchPolicy as WatchQueryFetchPolicy,
      })
      .valueChanges.pipe(
        map((result: any) => {
          if (result?.data) {
            return result.data;
          } else {
            return null;
          }
        })
      );
  }

  // Méthode générique pour effectuer une mutation GraphQL
  mutate(mutation: string, variables?: any, headers?: HttpHeaders): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql(mutation),
        variables,
        context: { headers },
      })
      .pipe(
        map((result: any) => {
          if (result?.data) {
            return result.data;
          } else {
            return null;
          }
        })
      );
  }
}
