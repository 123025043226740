import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard'
    },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 8,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true
    },
    {
        id: 11,
        label: 'MENUITEMS.FILEMANAGER.TEXT',
        icon: 'bx-file',
        link: '/filemanager',
        badge: {
            variant: 'success',
            text: 'MENUITEMS.FILEMANAGER.BADGE',
        },
    },
    {
        id: 12,
        label: 'MENUITEMS.CATALOGS.TEXT',
        icon: 'bx-book-open',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.CATALOGS.LIST.AllCATALOGS',
                link: '/catalogues/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.CATALOGS.LIST.ADDCATALOG',
                link: '/catalogues/add',
                parentId: 12
            },
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'bx-shopping-bag',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.PRODUCTS.LIST.AllPRODUCTS',
                link: '/products/all',
                parentId: 12
            }
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.CATEGORIES.TEXT',
        icon: 'bx-grid',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.CATEGORIES.LIST.AllCATEGORIES',
                link: '/categories/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.CATEGORIES.LIST.ADDCATEGORY',
                link: '/categories/add',
                parentId: 12
            },
        ]
    },
    
    {
        id: 12,
        label: 'MENUITEMS.BRANDS.TEXT',
        icon: 'bx-store',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.BRANDS.LIST.ALLBRANDS',
                link: '/marques/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.BRANDS.LIST.ADDBRAND',
                link: '/marques/add',
                parentId: 12
            },
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.MALLS.TEXT',
        icon: 'bx-buildings',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.MALLS.LIST.ALLMALLS',
                link: '/centres-commerciaux/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.MALLS.LIST.ADDMALL',
                link: '/centres-commerciaux/add',
                parentId: 12
            },
        ]
    },
    {
        id: 13,
        label: 'MENUITEMS.SECTIONS.TEXT',
        icon: 'bx-buildings',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.SECTIONS.LIST.ALLSECTIONS',
                link: '/sections/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.SECTIONS.LIST.ADDSECTION',
                link: '/sections/add',
                parentId: 12
            },
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.NEWSLETTERS.TEXT',
        icon: 'bx-bell',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.NEWSLETTERS.LIST.ALLNEWSLETTERS',
                link: '/newsletters/all',
                parentId: 12
            }
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'bx-user-circle',
        subItems: [
            {
                id: 14,
                label: 'MENUITEMS.USERS.LIST.ALLUSERS',
                link: '/users/all',
                parentId: 12
            },
            {
                id: 14,
                label: 'MENUITEMS.USERS.LIST.PROFIL',
                link: '/users/profil',
                parentId: 12
            }
        ]
    },
    {
        id: 12,
        label: 'MENUITEMS.STATS.TEXT',
        link: '/stats/all',
        icon: 'bx-stats',
    },
    {
        id: 80,
        label: 'MENUITEMS.COMPONENTS.TEXT',
        isTitle: true
    },
    {
        id: 118,
        label: 'MENUITEMS.ICONS.TEXT',
        icon: 'bx-aperture',
        subItems: [
            {
                id: 119,
                label: 'MENUITEMS.ICONS.LIST.BOXICONS',
                link: '/icons/boxicons',
                parentId: 118
            }
        ]
    }
];

