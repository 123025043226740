import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const token = this.authenticationService.getAuthToken();

        if (token) {
            const decodedToken = this.authenticationService.decodeToken(token);

            // Vérifier si le token est valide et si l'utilisateur a le rôle 3
            if (decodedToken && decodedToken.roleId === 3) {
                return true;
            }
        }

        // Rediriger vers la page de login avec l'URL de retour
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
